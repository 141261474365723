import React from "react"
import { Link } from "gatsby"

import Map from "./map"
import RichText from "./richtext"

const VisitSlice = ({ headline, body, cta, cta_link }) => (
  <div className="bg-white overflow-hidden">
    <div className="my-10 flex flex-col md:flex-row-reverse justify-center items-center mx-4">
      <div className="md:flex-1">
        <div className="md:max-w-lg md:mr-auto md:px-6 py-6">
          <h3 className="mb-4">{headline}</h3>
          <RichText html={body.html} />
          <Link to={cta_link.url}>{cta}</Link>
        </div>
      </div>
      <div className="md:flex-1 md:w-1/2">
        <div className="mx-4 overflow-hidden">
          <Map />
        </div>
      </div>
    </div>
  </div>
)

export default VisitSlice
