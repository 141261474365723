import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { handelize } from "../utils/utils"
import { filterValidNameFields } from "../utils/data"

const SHARED_ARROW_CLASSES = "border-b border-l w-3 h-3 transform border-black"

const NextArrow = ({ onClick }) => (
  <button onClick={onClick} className="absolute right-0 inset-y-0 mr-4 md:mr-8">
    <div className={`${SHARED_ARROW_CLASSES} -rotate-135`} />
  </button>
)

const PrevArrow = ({ onClick }) => (
  <button onClick={onClick} className="absolute left-0 inset-y-0 ml-4 md:ml-8">
    <div className={`${SHARED_ARROW_CLASSES} rotate-45`} />
  </button>
)

const ArtistsSlice = ({ headline, cta }) => {
  const { airtableArtists } = useStaticQuery(graphql`
    {
      airtableArtists: allAirtable(
        filter: { table: { eq: "Artists" } }
        sort: { fields: data___Last_Name }
      ) {
        edges {
          node {
            data {
              Name
              Featured_Art {
                data {
                  Name
                  Images {
                    localFiles {
                      childImageSharp {
                        fluid(maxWidth: 500) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const artists = filterValidNameFields(airtableArtists)

  const sliderSettings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    pauseOnDotsHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <div className="text-center my-5 md:my-10">
      <h3 className="mb-8">{headline}</h3>

      <Slider {...sliderSettings} className="px-8 md:px-16 mb-5 md:mb-10">
        {artists
          .filter(
            e =>
              e.node.data.Featured_Art &&
              e.node.data.Featured_Art[0].data.Images
          )
          .map(({ node }) => {
            const { Featured_Art, Name } = node.data

            return (
              <Link
                to={`/${handelize(Name)}`}
                className="no-underline block text-left w-1/2 md:w-1/3 px-3"
                key={Name}
              >
                <Img
                  fluid={{
                    ...Featured_Art[0].data.Images.localFiles[0].childImageSharp
                      .fluid,
                    aspectRatio: 1,
                  }}
                  alt={Featured_Art[0].data.Name}
                  className="w-full mb-3"
                />
                <h5>{Name}</h5>
              </Link>
            )
          })}
      </Slider>
      <Link to={`/artists`}>{cta}</Link>
    </div>
  )
}

export default ArtistsSlice
