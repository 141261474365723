import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import { formatDate, dateInFuture } from "../utils/data"

const Exhibitions = () => {
  const { allPrismicExhibit } = useStaticQuery(graphql`
    {
      allPrismicExhibit(sort: { fields: data___start_date }) {
        edges {
          node {
            uid
            data {
              name {
                text
              }
              subtitle
              start_date
              end_date
              image {
                fluid(maxWidth: 500) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
              short_description
            }
          }
        }
      }
    }
  `)

  const exhibitsToDisplay = allPrismicExhibit.edges.filter(exhibit =>
    dateInFuture(exhibit.node.data.end_date)
  )

  return (
    <>
      {exhibitsToDisplay.length && (
        <div className="max-w-5xl w-full mx-auto py-10">
          {exhibitsToDisplay.map(({ node }, i) => {
            const {
              name,
              subtitle,
              start_date,
              end_date,
              image,
              short_description,
            } = node.data
            return (
              <Link
                key={i}
                to={`/${node.uid}/`}
                className={classnames(
                  "no-underline flex w-full flex-col md:flex-row justify-center items-center py-5 md:py-10 px-4",
                  {
                    "md:flex-row-reverse": i === 1,
                  }
                )}
              >
                <Img fluid={{ ...image.fluid }} className="w-full flex-1" />
                <div className="flex-1">
                  <div
                    className={classnames("max-w-md p-4 md:p-6", {
                      "ml-auto": i === 1,
                    })}
                  >
                    <div className="pb-4">
                      <h2>{name.text}</h2>
                      {subtitle && <h4 className="pb-2">{subtitle}</h4>}
                    </div>
                    {short_description && (
                      <p className="pb-4">{short_description}</p>
                    )}
                    <p>
                      {formatDate(start_date)} - {formatDate(end_date)}
                    </p>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      )}
    </>
  )
}

export default Exhibitions
