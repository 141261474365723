import React from "react"

const Hero = ({ headline, subhead }) => (
  <div>
    <div className="max-w-4xl w-full mx-auto px-4">
      <div className="max-w-2xl py-16">
        <h1 className="mb-4">{headline}</h1>
        <p className="md:text-lg leading-tight">{subhead}</p>
      </div>
    </div>
  </div>
)

export default Hero
