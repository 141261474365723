import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import UpcomingEvents from "../components/upcoming-events"
import Exhibitions from "../components/exhibitions"
import ArtistsSlice from "../components/artists-slice"
import VisitSlice from "../components/visit-slice"

const IndexPage = ({ data }) => {
  const {
    hero_headline,
    hero_subhead,
    artists_header,
    artists_cta,
    visit_headline,
    visit_cta_text,
    visit_cta_link,
    visit_body,
  } = data.prismicHome.data
  return (
    <Layout>
      <SEO title="Home" />
      <Hero headline={hero_headline} subhead={hero_subhead} />
      <ArtistsSlice headline={artists_header} cta={artists_cta} />
      <Exhibitions />
      <UpcomingEvents />
      <VisitSlice
        headline={visit_headline}
        body={visit_body}
        cta={visit_cta_text}
        cta_link={visit_cta_link}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    prismicHome {
      data {
        hero_headline
        hero_subhead
        artists_header
        artists_cta
        visit_headline
        visit_cta_text
        visit_body {
          html
        }
        visit_cta_link {
          url
        }
      }
    }
  }
`
